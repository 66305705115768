var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-3 content-news-detail"},[_c('slick',_vm._b({ref:"slick"},'slick',_vm.slickOptions,false),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"pointer"},[_c('div',{staticClass:"header-image",style:(!_vm.list.length > 0
            ? {
                backgroundImage:
                  'url(' + '/assets/images/default-image.png' + ')'
              }
            : {
                backgroundImage: 'url(' + item + ')'
              })})])}),0),_c('div',{staticClass:"p-2",staticStyle:{"background-color":"#3a3a3a"}},[_c('div',{staticClass:"title-name"},[_vm._v(_vm._s(_vm.detail.name))]),_c('div',{staticClass:"title-time"},[_vm._v(" Date: "+_vm._s(_vm.detail.created_date ? _vm.dateFormat(_vm.detail.created_date) : "-")+" ")])]),_c('div',{staticClass:"description"},[_c('div',{staticClass:"sub-description"},[_vm._v("รายละเอียด")]),_c('div',{staticClass:"p-3",domProps:{"innerHTML":_vm._s(_vm.detail.description)}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }