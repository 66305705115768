<template>
  <div class="m-3 content-news-detail">
    <slick ref="slick" v-bind="slickOptions">
      <div v-for="(item, index) in list" :key="index" class="pointer">
        <div
          class="header-image"
          v-bind:style="
            !list.length > 0
              ? {
                  backgroundImage:
                    'url(' + '/assets/images/default-image.png' + ')'
                }
              : {
                  backgroundImage: 'url(' + item + ')'
                }
          "
        ></div>
      </div>
    </slick>
    <div class="p-2" style="background-color: #3a3a3a">
      <div class="title-name">{{ detail.name }}</div>
      <div class="title-time">
        Date: {{ detail.created_date ? dateFormat(detail.created_date) : "-" }}
      </div>
    </div>
    <div class="description">
      <div class="sub-description">รายละเอียด</div>
      <div class="p-3" v-html="detail.description"></div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import moment from "moment";
export default {
  components: {
    VueSlickCarousel
  },
  props: {
    list: {
      required: true,
      type: Array
    },
    detail: {
      required: true,
      type: Object
    },
    date: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      slickOptions: {
        fade: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        dots: true,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 6000
      }
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
      // return moment(date).locale("th").format('lll');
    }
  }
};
</script>

<style lang="scss" scoped>
.header-image {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.title {
  color: #fff;
}
.description {
  background-color: #fff;
}
.sub-description {
  padding: 10px;
  border-bottom: 1px solid gray;
  font-weight: 600;
}
.title-name {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.title-time {
  font-size: 13px;
  color: #fff;
}
.content-news-detail {
  min-height: 100vh;
}
::v-deep p img {
  width: 100% !important;
  height: auto !important;
}
::v-deep img {
  width: 100% !important;
  height: auto !important;
}
::v-deep table {
  width: 100% !important;
  height: auto !important;
}
::v-deep tr {
  height: auto !important;
}
::v-deep td {
  height: auto !important;
}
::v-deep .slick-dots li button:before {
  top: -30px;
}
::v-deep p {
  font-family: "Kanit", "Comic Sans MS", "Comic Sans", cursive;
}
</style>
